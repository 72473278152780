import DatePicker from "@rio-cloud/rio-uikit/DatePicker";
import ButtonDropdown from "@rio-cloud/rio-uikit/lib/es/ButtonDropdown";
import { getHours, getMinutes, startOfDay } from "date-fns";
import { isEqual } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, WrappedComponentProps, injectIntl, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserAccount } from "../../../configuration/login/loginSlice";
import { useAppSelector } from "../../../configuration/setup/hooks";
import { RootState } from "../../../configuration/setup/store";
import { paramsDate } from "../../../constants/params";
import useQueryParams from "../../../hooks/useQueryParams";
import { setAppDate } from "../../../stores/app/appSlice";
import {
	getLast15DaysPeriod,
	getLastMonthPeriod,
	getLastWeekPeriod,
	getLastYearToTodayPeriod,
	getTodayPeriod,
	getYesterdayPeriod,
	isJanuaryFirst,
} from "../../../utils/date";
import { isValidDateForToSelection, isValidDateStart } from "./helper";

interface DateSelectorProps extends WrappedComponentProps {
	className?: string;
}

interface DateItem {
	type: string;
	translationKey: string;
	date: any;
}

const DateSelector = ({ className }: DateSelectorProps) => {
	const { getQueryParam, setQueryParam } = useQueryParams();
	const dateTime = useSelector((state: RootState) => state.app.dateTime);
	const [startDay, setStartDay] = useState<any>(moment(dateTime.start));
	const [endDay, setEndDay] = useState<any>(moment(dateTime.end));
	const [isOpen, setIsOpen] = useState(false);
	const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
	const dispatch = useDispatch();
	const intl = useIntl();
	const initialMount = useRef(true);
	const urlDateParam = getQueryParam(paramsDate);
	const { pathname } = useLocation();
	const [disableDatepicker, setDisableDatepicker] = useState(false);
	const userAccountId = useAppSelector(getUserAccount);

	const initialDropdownItems: any[] = [
		{
			id: "1",
			type: "today",
			date: getTodayPeriod(),
			label: <FormattedMessage id="dateselector.datetime.today" />,
		},
		{
			id: "2",
			type: "yesterday",
			label: <FormattedMessage id="dateselector.datetime.yesterday" />,
			date: getYesterdayPeriod(),
		},
		{
			id: "3",
			type: "lastWeek",
			label: <FormattedMessage id="dateselector.datetime.lastWeek" />,
			date: getLastWeekPeriod(),
		},
		{
			id: "5",
			type: "lastMonth",
			label: <FormattedMessage id="dateselector.datetime.last15" />,
			date: getLast15DaysPeriod(),
		},
		{
			id: "6",
			type: "lastMonth",
			label: <FormattedMessage id="dateselector.datetime.lastMonth" />,
			date: getLastMonthPeriod(),
		},
	];

	const [dropdownItems, setDropdownItems] = useState<any[]>(initialDropdownItems);

	const isPresentationAccount =
		userAccountId === "7c821519-a280-43a6-8746-2c82b00530ed" ||
		userAccountId === "4924568e-23f3-40f9-9730-ff88efc41172" ||
		userAccountId === "local";

	useEffect(() => {
		if (!urlDateParam) {
			const { start, end } = getTodayPeriod();
			setStartDay(moment(start));
			setEndDay(moment(end));

			const dateTime = {
				start: moment(start).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
				end: moment(end).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
			};

			setQueryParam(paramsDate, JSON.stringify(dateTime));
			dispatch(setAppDate(dateTime));
		} else {
			let dateUrl = JSON.parse(urlDateParam);
			const dateUrlStart = new Date(dateUrl.start);
			const todayStart = startOfDay(new Date());

			if (isJanuaryFirst(moment(dateUrlStart))) {
				const dateTime = {
					start: moment(todayStart).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
					end: moment(endDay).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
				};

				setQueryParam(paramsDate, JSON.stringify(dateTime));
				dispatch(setAppDate(dateTime));
			}
		}
	}, []);

	const isDateDifferent =
		!isEqual(dateTime.start, moment(startDay).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ")) ||
		!isEqual(dateTime.end, moment(endDay).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"));

	useEffect(() => {
		if (!initialMount.current && endDay && !endDatePickerOpen && isDateDifferent) {
			const dateTime = {
				start: moment(startDay).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
				end: moment(endDay).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"),
			};
			setQueryParam(paramsDate, JSON.stringify(dateTime));
			dispatch(setAppDate(dateTime));
		} else {
			initialMount.current = false;
		}
	}, [endDatePickerOpen, endDay]);

	const handleRange = (startValue: any, endValue: any) => {
		setStartDay(startValue);
		const startingDay = moment(startValue);
		const endingDay = moment(endValue);

		if (
			startingDay.isAfter(endingDay) ||
			(!isJanuaryFirst(startingDay) && endingDay.diff(startingDay, "days") >= 30 && endingDay.month() !== startingDay.month())
		) {
			setEndDay(startingDay.clone().endOf("day"));
		} else {
			setEndDay(endValue);
		}
	};

	useEffect(() => {
		setIsOpen(false);
	}, [startDay]);

	useEffect(() => {
		if (pathname === "/downtime" && isPresentationAccount) {
			setDropdownItems((prevItems) => [
				...prevItems,
				{
					id: "7",
					type: "lastYear",
					label: <FormattedMessage id="dateselector.datetime.lastYear" />,
					date: getLastYearToTodayPeriod(),
				},
			]);
		}
	}, []);

	const createDropdownItem = (item: any) => {
		const { date, type, label } = item;
		const fromDate = date.start;
		const toDate = date.end;

		const handleItemClick = () => {
			handleRange(fromDate, toDate);
			setIsOpen(!isOpen);
		};

		return (
			<li key={type} role="presentation" className={"width-200"} onClick={() => handleItemClick()}>
				<a role="menuitem">{label}</a>
			</li>
		);
	};

	useEffect(() => {
		if (isPresentationAccount && pathname === "/downtime") {
			if (isJanuaryFirst(moment(dateTime.start))) {
				setDisableDatepicker(true);
			} else {
				setDisableDatepicker(false);
			}
		}
	}, [dateTime.start]);

	const customDropdown = dropdownItems.map((item) => createDropdownItem(item));
	const endDatePickerInput = useRef<any>(null);
	const endDatePickerRef = useRef<any>(null);
	const startDatePickerRef = useRef<any>(null);

	const now = new Date();
	const nowHour = getHours(now);
	const nowMinute = getMinutes(now);
	const isTodayStart = moment(0, "HH").diff(startDay, "days") == 0;
	const isTodayEnd = moment(0, "HH").diff(endDay, "days") == 0;

	return (
		<>
			<div className={`display-flex gap-5 justify-content-start ${className}`}>
				<div className="width-auto">
					<ButtonDropdown
						className={"position-relative"}
						open={isOpen}
						title={<span className={"rioglyph rioglyph rioglyph-settings"} aria-hidden={"true"} />}
						customDropdown={customDropdown}
						onOpen={() => setIsOpen(true)}
						onClose={() => setIsOpen(false)}
						iconOnly
					/>
				</div>
				<div className="width-50pct">
					<DatePicker
						ref={startDatePickerRef}
						closeOnSelect={true}
						closeOnClickOutside={true}
						inputProps={{ disabled: disableDatepicker }}
						timeConstraints={{
							hours: {
								min: 0,
								max: isTodayStart ? nowHour : 23,
								step: 1,
							},
							minutes: {
								min: 0,
								max: isTodayStart ? nowMinute : 59,
								step: 1,
							},
						}}
						isValidDate={(date) => isValidDateStart(date)}
						locale={intl.locale}
						value={moment(startDay)}
						onChange={(selectedDate) => {
							handleRange(moment(selectedDate), endDay);
						}}
						onClose={() => {
							setTimeout(() => {
								endDatePickerInput.current.focus();
							}, 100);
						}}
					/>
				</div>
				<div className="width-50pct">
					<DatePicker
						ref={endDatePickerRef}
						closeOnSelect={true}
						alignRight={true}
						closeOnClickOutside={true}
						locale={intl.locale}
						value={moment(endDay)}
						timeConstraints={{
							hours: {
								min: 0,
								max: isTodayEnd ? nowHour : 23,
								step: 1,
							},
							minutes: {
								min: 0,
								max: isTodayEnd ? nowMinute : 59,
								step: 1,
							},
						}}
						inputProps={{ ref: endDatePickerInput, disabled: disableDatepicker }}
						isValidDate={(date) => isValidDateForToSelection(date, startDay)}
						onOpen={() => setEndDatePickerOpen(true)}
						onChange={(selectedDate) => handleRange(startDay, moment(selectedDate))}
						onClose={() => setEndDatePickerOpen(false)}
					/>
				</div>
			</div>
		</>
	);
};

export default injectIntl(DateSelector);
