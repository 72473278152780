export const chartColors: string[] = [
	"#7dccd4",
	"#f5c11b",
	"#5cb85c",
	"#104651",
	"#7e3e72",
	"#b23672",
	"#268897",
	"#154b56",
	"#c9778d",
	"#ff8e3c",
	"#f5bb89",
	"#032f38",
	"#4b924c",
	"#206e7e",
	"#79619f",
	"#239b7d",
	"#3690ae",
	"#044553",
	"#30b4c0",
	"#b23672",
];
