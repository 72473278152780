import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "../configuration/tokenHandling/prepareHeaders";

export const customReportApi = createApi({
	reducerPath: "customReportApi",
	baseQuery: fetchBaseQuery({ prepareHeaders }),
	endpoints: (builder) => ({
		getCharts: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report",
			}),
		}),
		getListChartsIds: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/informations",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		createChart: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/config",
			}),
		}),
		getSingleChartById: builder.mutation<any, { customId: string; requestPayload: any }>({
			query: ({ customId, requestPayload }) => ({
				url: `https://api.latam-perform.rio.cloud/rio-perform/custom-report/${customId}`,
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data[0];
				},
			}),
		}),
		updateSingleChart: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "PUT",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/config",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data[0];
				},
			}),
		}),
		deleteSingleChart: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "DELETE",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/custom-report/config",
			}),
		}),
	}),
});

export const {
	useGetListChartsIdsMutation,
	useGetChartsMutation,
	useUpdateSingleChartMutation,
	useCreateChartMutation,
	useGetSingleChartByIdMutation,
	useDeleteSingleChartMutation,
} = customReportApi;
