import { useAppSelector } from "../../../configuration/setup/hooks";
import { getCustomReportPageData } from "../../../stores/customReport/customReportPageSlice";
import CustomReportBarChart from "../types/CustomReportBarChart";
import CustomReportHistogramChart from "../types/CustomReportHistogramChart";
import CustomReportLineChart from "../types/CustomReportLineChart";
import CustomReportPieChart from "../types/CustomReportPieChart";

const ChartDecider = ({ chart }: any) => {
	switch (chart.chartType) {
		case "BAR":
			if (chart.mainData === "HISTOGRAM") {
				return <CustomReportHistogramChart chartData={chart} />;
			}
			return <CustomReportBarChart chartData={chart} />;
		case "PIE":
			return <CustomReportPieChart chartData={chart} />;
		case "LINE":
			return <CustomReportLineChart chartData={chart} />;
		default:
			return <div>Unknown chart type</div>;
	}
};

export default ChartDecider;
