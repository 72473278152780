import { createHashRouter, createRoutesFromElements, Route } from "react-router-dom";
import { ErrorBoundary } from "../components/error/ErrorBoundary";
import AppLayout from "../layout/AppLayout";
import { suspendPageLoad } from "./suspendPageLoad";
import CustomReportPage from "../pages/custom/CustomReportPage";

const Driving = suspendPageLoad(() => import("../pages/driving-analysis/DrivingPage"));
const Infractions = suspendPageLoad(() => import("../pages/infractions/InfractionsPage"));

export const DRIVING_ROUTE = "/driving-analysis";
export const INFRACTIONS_ROUTE = "/infractions";
export const CUSTOM_ROUTE = "/custom-report";
export const routes = [DRIVING_ROUTE, INFRACTIONS_ROUTE, CUSTOM_ROUTE];

export const router = createHashRouter(
	createRoutesFromElements(
		<Route path="/" element={<AppLayout />}>
			<Route path={DRIVING_ROUTE} errorElement={<ErrorBoundary />} element={<Driving />} />
			<Route path={INFRACTIONS_ROUTE} errorElement={<ErrorBoundary />} element={<Infractions />} />
			<Route path={CUSTOM_ROUTE} errorElement={<ErrorBoundary />} element={<CustomReportPage />} />
		</Route>,
	),
);
