import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";
import SimpleTooltip from "@rio-cloud/rio-uikit/components/tooltip/SimpleTooltip";
import useOnlineStatus from "@rio-cloud/rio-uikit/useOnlineStatus";
import { DefaultUserMenu } from "@rio-cloud/rio-user-menu-component";
import IframeResizer from "iframe-resizer-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { LinkWithQuery } from "../../components/link/LinkWithQuery";
import { config } from "../../config";
import { CUSTOM_ROUTE, DRIVING_ROUTE, INFRACTIONS_ROUTE } from "../../routes/Router";
import ServiceInfo from "./ServiceInfo";

const AppHeader = () => {
	const environment = import.meta.env.PROD ? "production" : "local";

	const navItems = [
		{
			key: "driving",
			route: (
				<LinkWithQuery to={DRIVING_ROUTE}>
					<FormattedMessage id={"general.app.page.driving"} />
				</LinkWithQuery>
			),
		},
		{
			key: "infractions",
			route: <LinkWithQuery to={INFRACTIONS_ROUTE}>Infrações</LinkWithQuery>,
		},
		{
			key: "custom",
			route: <LinkWithQuery to={CUSTOM_ROUTE}>Relatório Customizado</LinkWithQuery>,
		},
	];

	const isOnline = useOnlineStatus();
	const serviceInfoItem = <ServiceInfo />;
	const userMenuItem = <DefaultUserMenu environment={environment} />;

	const isAppOnline = isOnline ? (
		<SimpleTooltip content={"Sistema online"} placement="bottom">
			<span className="text-success rioglyph rioglyph-ok-sign text-size-h3" />
		</SimpleTooltip>
	) : (
		<SimpleTooltip content={"Sistema offline"} placement="bottom">
			<span className="text-danger rioglyph rioglyph-remove-sign text-size-h3" />
		</SimpleTooltip>
	);

	return (
		<>
			<ApplicationHeader
				label={<FormattedMessage id="general.app.name" />}
				appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
				homeRoute={<Link to={config.homeRoute || ""} />}
				navItems={navItems}
				actionBarItems={[isAppOnline, serviceInfoItem, userMenuItem]}
			/>
		</>
	);
};

export default AppHeader;
