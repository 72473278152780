import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const CustomReportLineChart = ({ chartData, statusValue }: any) => {
	const transformData = (rawData: any) => {
		const result: any = [];

		const uniqueDates = [...new Set(rawData?.map((item: any) => item.axisX))];

		uniqueDates.forEach((date) => {
			const entry: any = { axisX: date };

			const dataPoint = rawData?.find((item: any) => item.axisX === date);
			if (dataPoint) {
				dataPoint?.axisY?.forEach((y: any) => {
					entry[y.name] = parseFloat(y.value);
				});
			}

			result.push(entry);
		});

		return result;
	};

	const transformedData = transformData(chartData?.chart);

	const getLineColor = (index: any) => {
		const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#0088fe", "#00c49f"];
		return colors[index % colors.length];
	};

	const lineNames = chartData.chart[0]?.axisY.map((y: any) => y.name) || [];

	if (statusValue === "loading") {
		return (
			<div className="flex items-center justify-center h-full">
				<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
			</div>
		);
	}

	return (
		<ResponsiveContainer width="100%" height={400}>
			<LineChart
				data={transformedData}
				margin={{
					top: 20,
					right: 30,
					left: 20,
					bottom: 20,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="axisX" tick={{ fontSize: 12 }} padding={{ left: 20, right: 20 }} />
				<YAxis tick={{ fontSize: 12 }} />
				<Tooltip />
				<Legend verticalAlign="top" height={36} wrapperStyle={{ fontSize: "12px" }} />
				{lineNames.map((name: string, index: number) => (
					<Line
						key={name}
						type="monotone"
						dataKey={name}
						stroke={getLineColor(index)}
						strokeWidth={2}
						dot={{ r: 4 }}
						activeDot={{ r: 6 }}
					/>
				))}
			</LineChart>
		</ResponsiveContainer>
	);
};

export default CustomReportLineChart;
