import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@rio-cloud/rio-uikit";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { getUserAccount } from "../../../configuration/login/loginSlice";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { useCreateChartMutation, useGetSingleChartByIdMutation, useUpdateSingleChartMutation } from "../../../services/customReportApi";
import { getAppData } from "../../../stores/app/appSlice";
import { addChart, getCustomReportSidesheet, setOpenCustomReportSidesheet } from "../../../stores/customReport/customReportPageSlice";

const chartFormSchema = z
	.object({
		chartName: z
			.string()
			.nonempty({ message: "O nome do gráfico é obrigatório" })
			.max(150, { message: "O nome do gráfico deve ter no máximo 150 caracteres" }),
		chartType: z.enum(["LINE", "BAR", "PIE"], {
			required_error: "O tipo de gráfico é obrigatório",
		}),
		subject: z.enum(["VEHICLE", "DRIVER"], {
			required_error: "O assunto do gráfico é obrigatório",
		}),
		mainData: z.string().min(1, { message: "O intervalo de tempo não pode ser vazio" }),
		complementaryData: z.string().optional(),
		timeRange: z.string().optional(), // We make timeRange optional and add custom validation later
	})
	.superRefine((data, ctx) => {
		// Validate complementaryData for BAR chart type
		if (data.chartType === "BAR" && (!data.complementaryData || data.complementaryData.trim().length === 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "O conteúdo complementar é obrigatório para gráficos de barras",
				path: ["complementaryData"],
			});
		}

		// Validate timeRange conditionally based on mainData
		if (data.mainData !== "HISTOGRAM" && (!data.timeRange || data.timeRange.trim().length === 0)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "O intervalo de tempo é obrigatório, exceto para o tipo de dados HISTOGRAM",
				path: ["timeRange"],
			});
		}
	});

const CustomReportSidesheet = () => {
	const isHistogramAccount = useAppSelector(getUserAccount);
	const { dateTime, assets, tags } = useAppSelector(getAppData);
	const [createChart, { data, isSuccess: isCreated }] = useCreateChartMutation();
	const [updateChart] = useUpdateSingleChartMutation();
	const [getSingleChartById] = useGetSingleChartByIdMutation();
	const dispatch = useAppDispatch();
	const chartFromGrid = useAppSelector(getCustomReportSidesheet);
	const chartToEdit = chartFromGrid.chart;

	const {
		register,
		handleSubmit,
		watch,
		trigger,
		reset,
		formState: { errors, isValid, isDirty },
		setValue,
	} = useForm({
		mode: "all",
		resolver: zodResolver(chartFormSchema),
	});

	useEffect(() => {
		if (chartToEdit) {
			reset({
				chartName: chartToEdit.chartName,
				chartType: chartToEdit.chartType,
				subject: chartToEdit.subject,
				mainData: chartToEdit.mainData,
				complementaryData: chartToEdit.complementaryData || "",
				timeRange: chartToEdit.timeRange,
			});
		} else {
			reset({
				chartName: "",
				chartType: "",
				subject: "",
				mainData: "",
				complementaryData: "",
				timeRange: "",
			});
		}
	}, [chartToEdit, reset]);

	useEffect(() => {
		if (!chartToEdit) {
			reset();
		}
	}, [chartToEdit]);

	const optionsLine = [
		{ id: "DISTANCE", label: "Distância" },
		{ id: "ENGINE_HOURS", label: "Motor ligado" },
		{ id: "MAX_SPEED", label: "Velocidade máxima" },
		{ id: "FUEL", label: "Combustível" },
		{ id: "AVERAGE_FUEL", label: "Média de combustível" },
		{ id: "TRIP", label: "Viagens" },
	];

	const optionsBarWithHistogram = [
		{ id: "INFRACTIONS", label: "Infrações" },
		{ id: "HISTOGRAM", label: "Histograma" },
	];

	const optionsComplementaryHistogram = [
		{ id: "SPEED", label: "Velocidade" },
		{ id: "RPM", label: "RPM" },
		{ id: "ACCELERATOR", label: "Acelerador" },
		{ id: "ENGINE_BRAKE", label: "Freio motor" },
		{ id: "CRUISE_CONTROL", label: "Controle de cruzeiro" },
		{ id: "TIME_WITH_PTO", label: "Tempo em PTO" },
		{ id: "GEAR", label: "Marcha" },
	];

	const optionsPie = [
		{ id: "INFRACTIONS", label: "Infrações" },
		{ id: "DISTANCE", label: "Distância" },
		{ id: "ENGINE_HOURS", label: "Motor ligado" },
		{ id: "MAX_SPEED", label: "Velocidade máxima" },
		{ id: "FUEL", label: "Combustível" },
		{ id: "AVERAGE_FUEL", label: "Média de combustível" },
		{ id: "TRIP", label: "Viagens" },
	];

	const optionsComplementaryData = [
		{ id: "ALL_INFRACTIONS", label: "Todas infrações" },
		{ id: "IT_KICKDOWN", label: "Arrancada" },
		{ id: "IT_RPM", label: "RPM" },
		{ id: "IT_SPEED", label: "Velocidade" },
		{ id: "IT_SPEED_RAIN", label: "Velocidade na chuva" },
		{ id: "IT_IDLING", label: "Marcha lenta" },
		{ id: "IT_CLUTCH", label: "Embreagem" },
		{ id: "IT_ACCELERATION", label: "Aceleração" },
	];

	const optionsBar = [{ id: "INFRACTIONS", label: "Infrações" }];

	const selectedChartType = watch("chartType");
	const selectedMainData = watch("mainData");
	const selectedComplementaryData = watch("complementaryData");
	const selectedSubject = watch("subject");

	const hideComplementaryData =
		selectedMainData === "TRIP" ||
		!selectedMainData ||
		(!selectedComplementaryData && selectedChartType == "LINE") ||
		(!selectedComplementaryData && selectedChartType == "PIE");

	const getMainDataOptions = () => {
		switch (selectedChartType) {
			case "LINE":
				return optionsLine;
			case "BAR":
				return isHistogramAccount ? optionsBarWithHistogram : optionsBar;
			case "PIE":
				return optionsPie;
			default:
				return [];
		}
	};

	// useEffect(() => {
	// 	if (
	// 		chartToEdit?.mainData !== selectedMainData ||
	// 		chartToEdit?.subject !== selectedSubject ||
	// 		chartToEdit?.chartType !== selectedChartType
	// 	) {
	// 		setValue("complementaryData", "");
	// 		setValue("timeRange", "");
	// 	}
	// }, [selectedSubject, selectedMainData, selectedChartType, setValue]);

	useEffect(() => {
		if (selectedChartType && !chartToEdit) {
			setValue("complementaryData", "");
		}
	}, [selectedMainData, selectedSubject, setValue]);

	useEffect(() => {
		if (selectedChartType && !chartToEdit) {
			setValue("mainData", "");
			trigger();
		}
	}, [selectedChartType, setValue]);

	const getComplementaryDataOptions = () => {
		return selectedMainData === "HISTOGRAM" ? optionsComplementaryHistogram : optionsComplementaryData;
	};

	const showTimeRange = selectedMainData !== "HISTOGRAM";

	const onSubmit = async (data: any) => {
		const chartToBeCreated = {
			...data,
			columnSize: 5,
			complementaryData:
				selectedComplementaryData === "ALL_INFRACTIONS" || selectedComplementaryData === "" ? null : selectedComplementaryData,
			viewOrder: 99,
			startAt: dateTime.start,
			endAt: dateTime.end,
		};

		if (chartToEdit) {
			const chartToUpdate = {
				...data,
				id: chartToEdit.id,
				complementaryData:
					selectedComplementaryData === "ALL_INFRACTIONS" || selectedComplementaryData === "" ? null : selectedComplementaryData,
				startAt: chartToEdit.startAt + "-03:00",
				endAt: chartToEdit.endAt + "-03:00",
			};
			const result = await updateChart([chartToUpdate]).unwrap();
			dispatch(setOpenCustomReportSidesheet({ open: false, chart: null }));

			const fetchedChart = await getSingleChartById({
				customId: result.id,
				requestPayload: {
					assetIds: assets,
					tagIds: tags,
					startAt: subDays(dateTime.start, 30),
					endAt: dateTime.end,
					infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
					page: 0,
					pageSize: 50,
					search: "",
					zone: "UTC-3",
				},
			}).unwrap();
			dispatch(addChart(fetchedChart));
		} else {
			const result = await createChart(chartToBeCreated).unwrap();
			if (result?.id) {
				dispatch(setOpenCustomReportSidesheet({ open: false, chart: null }));

				const fetchedChart = await getSingleChartById({
					customId: result.id,
					requestPayload: {
						assetIds: assets,
						tagIds: tags,
						startAt: subDays(dateTime.start, 30),
						endAt: dateTime.end,
						infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
						page: 0,
						pageSize: 50,
						search: "",
						zone: "UTC-3",
					},
				}).unwrap();
				dispatch(addChart(fetchedChart));
			}
		}
		reset();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-group">
				<label>Nome do gráfico</label>
				<input {...register("chartName")} className="form-control" maxLength={40} placeholder="Digite o nome do gráfico" />
				{errors.chartName && <span>{errors.chartName.message?.toString()}</span>}
			</div>
			<div className="form-group">
				<label>Tipo do gráfico</label>
				<div className="display-flex gap-20 align-items-center">
					<label className="radio" htmlFor="radio-bar">
						<input type="radio" id="radio-bar" {...register("chartType")} value="BAR" />
						<span className="radio-text">Barras</span>
					</label>
					<label className="radio" htmlFor="radio-line">
						<input type="radio" id="radio-line" {...register("chartType")} value="LINE" />
						<span className="radio-text">Linha</span>
					</label>
					<label className="radio" htmlFor="radio-pie">
						<input type="radio" id="radio-pie" {...register("chartType")} value="PIE" />
						<span className="radio-text">Pizza</span>
					</label>
				</div>
				{errors.chartType && <span>{errors.chartType.message?.toString()}</span>}
			</div>

			<div className="form-group">
				<label>Assunto do gráfico</label>
				<div className="display-flex gap-20 align-items-center">
					<label className="radio" htmlFor="radio-veiculo">
						<input type="radio" id="radio-veiculo" {...register("subject")} value="VEHICLE" />
						<span className="radio-text">Veículo</span>
					</label>
					<label className="radio" htmlFor="radio-motorista">
						<input type="radio" id="radio-motorista" {...register("subject")} value="DRIVER" />
						<span className="radio-text">Motorista</span>
					</label>
				</div>
				{errors.subject && <span>{errors.subject.message?.toString()}</span>}
			</div>

			<div className="form-group">
				<label>Conteúdo principal</label>
				<select {...register("mainData")} className="form-control">
					<option value="" hidden={true}>
						Selecione
					</option>
					{getMainDataOptions().map((option) => (
						<option key={option.id} value={option.id}>
							{option.label}
						</option>
					))}
				</select>
				{errors.mainData && <span>{errors.mainData.message?.toString()}</span>}
			</div>
			{!hideComplementaryData && (
				<div className="form-group">
					<label>Conteúdo complementar</label>
					<select
						{...register("complementaryData")}
						className="form-control"
						onChange={() => (selectedMainData === "HISTOGRAM" ? setValue("timeRange", "") : undefined)}
					>
						<option value="" hidden={true}>
							Selecione
						</option>
						{getComplementaryDataOptions().map((option) => (
							<option key={option.id} value={option.id}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			)}
			{showTimeRange && (
				<div className="form-group">
					<label>Intervalo de tempo</label>
					<div className="display-flex gap-20 align-items-center">
						<label className="radio" htmlFor="radio-daily">
							<input type="radio" id="radio-daily" {...register("timeRange")} value="DAILY" />
							<span className="radio-text">Diário</span>
						</label>
						<label className="radio" htmlFor="radio-weekly">
							<input type="radio" id="radio-weekly" {...register("timeRange")} value="WEEKLY" />
							<span className="radio-text">Semanal</span>
						</label>
						<label className="radio" htmlFor="radio-monthly">
							<input type="radio" id="radio-monthly" {...register("timeRange")} value="MONTHLY" />
							<span className="radio-text">Mensal</span>
						</label>
					</div>
					{errors.timeRange && <span>{errors.timeRange.message?.toString()}</span>}
				</div>
			)}
			<div className="margin-y-25">
				<Button type="submit" disabled={!isValid} block={true} bsStyle={Button.PRIMARY}>
					Criar
				</Button>
			</div>
		</form>
	);
};

export default CustomReportSidesheet;
