import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareHeaders } from "../configuration/tokenHandling/prepareHeaders";

export const drivingApi = createApi({
	reducerPath: "drivingApi",
	baseQuery: fetchBaseQuery({ prepareHeaders }),
	endpoints: (builder) => ({
		getDrivingCards: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/driving-analysis/cards",
			}),
		}),
		getDrivingAnalysis: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/driving-analysis",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getMeasurement: builder.mutation<any, any>({
			query: (requestPayload) => ({
				method: "POST",
				body: requestPayload,
				url: "https://api.latam-perform.rio.cloud/rio-perform/measurement",
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
	}),
});

export const { useGetDrivingCardsMutation, useGetDrivingAnalysisMutation, useGetMeasurementMutation } = drivingApi;
