import { ContentLoader, Spinner } from "@rio-cloud/rio-uikit";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { useDeleteSingleChartMutation, useGetSingleChartByIdMutation } from "../../../services/customReportApi";
import { getAppData } from "../../../stores/app/appSlice";
import { deleteChart, getUpdatedChart } from "../../../stores/customReport/customReportPageSlice";
import ChartDecider from "../chart/ChartDecider";
import ChartHeader from "../chart/ChartHeader";

type ChartItemProps = {
	chart: any;
	isLoading: boolean;
};

const ChartItem = ({ chart, isLoading }: ChartItemProps) => {
	const [currentChart, setCurrentChart] = useState(chart);
	const [loadingCharts, setLoadingCharts] = useState<boolean>(false);
	const [deleteSingleChart] = useDeleteSingleChartMutation();
	const [getSingleChartById] = useGetSingleChartByIdMutation();
	const { assets, tags, dateTime } = useAppSelector(getAppData);
	const updatedChart = useAppSelector(getUpdatedChart);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (updatedChart && updatedChart?.id === currentChart?.id) {
			setCurrentChart(updatedChart);
		}
	}, [updatedChart, currentChart?.id]);

	const handleDeleteChart = async () => {
		try {
			const chartToDelete = {
				...currentChart,
				startAt: `${currentChart.startAt}-03:00`,
				endAt: `${currentChart.endAt}-03:00`,
			};

			await deleteSingleChart(chartToDelete);
			dispatch(deleteChart(chartToDelete));
		} catch (error) {
			console.error("Error deleting chart:", error);
		}
	};

	const handleDateChange = async () => {
		setLoadingCharts(true);

		try {
			const updatedChart = await getSingleChartById({
				customId: chart.id,
				requestPayload: {
					assetIds: assets,
					tagIds: tags,
					startAt: dateTime.start,
					endAt: dateTime.end,
					zone: "UTC-3",
					infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
					search: "",
					page: 0,
					pageSize: 50,
				},
			}).unwrap();

			setCurrentChart(updatedChart);
		} catch (error) {
			console.error("Error updating chart:", error);
		} finally {
			setLoadingCharts(false);
		}
	};

	useEffect(() => {
		handleDateChange();
	}, [dateTime]);

	useEffect(() => {
		setCurrentChart(chart);
	}, [chart]);

	if (!currentChart) return <ContentLoader height={400} className="padding-x-50 padding-y-20" />;

	return (
		<div className="bg-white flex flex-column gap-5 rounded padding-y-20">
			<div className="height-5pct display-flex flex-column justify-content-center padding-x-50" style={{ zIndex: 2 }}>
				<ChartHeader chartFiltered={currentChart} onDelete={handleDeleteChart} />
				<span className="text-size-h4 text-center">{currentChart.chartName}</span>
			</div>
			<div className="height-90pct padding-y-5">
				{loadingCharts || isLoading ? (
					<ContentLoader height={400} className="padding-x-50 padding-y-20" />
				) : (
					<ChartDecider chart={currentChart} />
				)}
			</div>
		</div>
	);
};

export default ChartItem;
