import { ContentLoader } from "@rio-cloud/rio-uikit";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../configuration/setup/hooks";
import { useGetSingleChartByIdMutation, useUpdateSingleChartMutation } from "../../../services/customReportApi";
import { getAppData } from "../../../stores/app/appSlice";
import { getCustomReportPageData, setTypeOfChart } from "../../../stores/customReport/customReportPageSlice";
import ChartItem from "../grid/ChartItem";

const ChartModal = ({ openChart, chart }: any) => {
	const [chartData, setChartData] = useState<any>(null);
	const [loadingChart, setLoadingChart] = useState<boolean>(true);
	const [getSingleChartById, { isLoading }] = useGetSingleChartByIdMutation();
	const { dateTime, tags, assets } = useAppSelector(getAppData);
	const [updateChart] = useUpdateSingleChartMutation();
	const { typeOfChart } = useAppSelector(getCustomReportPageData);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchChartData = async () => {
			if (openChart && chart) {
				setLoadingChart(true);

				try {
					const fetchedChart = await getSingleChartById({
						customId: chart.id,
						requestPayload: {
							assetIds: assets,
							tagIds: tags,
							startAt: dateTime.start,
							endAt: dateTime.end,
							zone: "UTC-3",
							infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
							search: "",
							page: 0,
							pageSize: 50,
						},
					}).unwrap();
					setChartData(fetchedChart);
					dispatch(setTypeOfChart(fetchedChart.chartType));
				} catch (error) {
					console.error("Error fetching chart data:", error);
				} finally {
					setLoadingChart(false);
				}
			}
		};

		fetchChartData();
	}, [openChart, chart, getSingleChartById, assets, tags, dateTime]);

	useEffect(() => {
		if (typeOfChart) {
			updateChart2();
		}
	}, [typeOfChart]);

	const updateChart2 = async () => {
		try {
			const chartToUpdate = {
				...chartData,
				id: chart.id,
				chartType: typeOfChart,
				complementaryData: chart.complementaryData === "ALL_INFRACTIONS" || chart.complementaryData === "" ? null : chart.complementaryData,
				startAt: dateTime.start,
				endAt: dateTime.end,
			};

			const result = await updateChart([chartToUpdate]).unwrap();

			const updatedChart = await getSingleChartById({
				customId: chartToUpdate.id,
				requestPayload: {
					assetIds: assets,
					tagIds: tags,
					startAt: dateTime.start,
					endAt: dateTime.end,
					infractions: ["IT_IDLING", "IT_IDLING_AC", "IT_RPM", "IT_KICKDOWN", "IT_SPEED", "IT_ACCELERATION", "IT_BRAKING", "IT_CLUTCH"],
					page: 0,
					pageSize: 50,
					search: "",
					zone: "UTC-3",
				},
			}).unwrap();

			setChartData(updatedChart);
		} catch (error) {
			console.error("Error updating chart:", error);
		}
	};

	return (
		<>
			{loadingChart ? (
				<ContentLoader height={400} width={"100%"} />
			) : (
				<>
					<ChartItem chart={chartData} isLoading={isLoading} />
				</>
			)}
		</>
	);
};

export default ChartModal;
