import { isBefore } from "date-fns";
import moment, { Moment } from "moment";

export const isValidDateForToSelection = (dateFromCalendar: Moment, startDay: Moment): boolean => {
	const today = moment();
	return dateFromCalendar.isSameOrAfter(startDay) && dateFromCalendar.isSameOrBefore(today);
};

export const isValidDateStart = (dateFromCalendar: Date): boolean => {
	const today = new Date();
	const currentDate = new Date(dateFromCalendar);
	return isBefore(new Date(currentDate), today);
};
